function Main(){
    return(
        <div className="bg-gradient-to-br from-blue-300 via-blue-500 to-blue-700" id="main">
        <div className="pt-[72px] w-full h-screen flex flex-col justify-center align-middle content-center items-center">
          <img alt="" src="miningvoxelslogomid.png" className="w-[500px] h-[350px] pt-8"></img>
          <div className="w-full h-screen flex flex-col md:flex-row justify-center content-center items-center gap-12">
            <img alt="" src="manor3.png" className="w-[500px] h-[500px] hidden md:block"></img>
            <div className="flex flex-col justify-center items-center md:items-start gap-6 m-6 max-w-[780px]">
              <h1 className="font-pixel antialiased text-4xl">MiningVoxels is a large play-to-earn game that simulates the real-world economy.</h1>
              <button className="w-40 h-16 bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 rounded-xl shadow-lg text-2xl font-pixel text-white hover:text-black"><a href="https://play.miningvoxels.com/">PLAY</a></button>
            </div>
          </div>
        </div>
        
      </div>
    );
}

export default Main;