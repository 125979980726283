function NFTs(){
    return(
        <div className="bg-gradient-to-br from-pink-700 via-purple-500 to-purple-700" id="nfts">
        <div className="relative text-center py-4 text-white">
          <h1 className="text-8xl font-pixel">NFTs</h1>
        </div>
        <div className="w-full flex flex-col justify-center items-center content-center">
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">MINING EQUIPMENTS</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">Mining equipments are used to earn 'COAL,STONE,IRON,SILVER,GOLD,DIAMOND' in game tokens.</span>
            </div>
            <img alt="" src="diapickaxe.png" className="w-[300px]"></img>
          </div>
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
          <img alt="" src="silveraxe.png" className="w-[300px]"></img>
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">LUMBERING EQUIPMENTS</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">Lumbering equipments are used to earn 'WOOD' in game tokens</span>
            </div>
          </div>
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">HUNTING EQUIPMENTS</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">Hunting equipments are used to earn 'MEAT' in game tokens</span>
            </div>
            <img alt="" src="shotgun.png" className="w-[300px]"></img>
          </div>
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
          <img alt="" src="smallboat2.png" className="w-[300px]"></img>
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">FISHING EQUIPMENTS</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">Fishing equipments are use to earn 'FISH' in game tokens.</span>
            </div>
            
          </div>
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">FARMING EQUIPMENTS</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">Farming equipments are used to earn 'WHEAT' in game tokens.</span>
            </div>
            <img alt="" src="tractor.png" className="w-[300px]"></img>
          </div>
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
          <img alt="" src="villa.png" className="w-[300px]"></img>
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">HOUSES</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">Houses are best choice to earn 'ENERGY'. Go home and rest well.</span>
            </div>
          </div>
          <div className="w-[80%] flex flex-col md:flex-row border-4 p-16 bg-white rounded-2xl justify-between items-center my-4 shadow-lg">
            <div className="flex flex-col">
              <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">BULKS</span>
              <span className="font-pixel text-xl md:text-2xl lg:text-3xl">You can make bulk NFTs with in game tokens. You can use for NFT Marketplaces, trades etc.</span>
            </div>
            <img alt="" src="Wood.png" className="w-[300px]"></img>
          </div>
        </div>
      </div>
    );
}

export default NFTs;