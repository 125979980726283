import NavBar from "./components/navbar";
import Main from "./components/main";
import RoadMap from './components/roadmap';
import NFTs from "./components/nfts";
import Team from "./components/team";
import Footer from "./components/footer";
import Token from "./components/token";
import Discord from "./components/discord";


function App() {
  return (
    <div className="block m-0 p-0 box-border w-full h-full bg-gradient-to-br from-blue-100 via-blue-300 to-blue-500 overflow-x-hidden">
      <NavBar/>
      <Main/>
      <Team/>
      <Discord/>
      <Token/>
      <NFTs/>
      <RoadMap/>
      <Footer/>

    </div>
  );
}

export default App;
