import {BiArrowToRight} from "react-icons/bi";

function NavBar(){
    return(
        <nav className="fixed top-0 left-0 bg-white w-full shadow-2xl z-10">
        <div className="container m-auto flex justify-between items-center text-gray-700">
          <h1 className="pl-8 py-4 text-4xl font-pixel antialiased"><a href="#main">MININGVOXELS</a></h1>
          <ul className="hidden md:flex items-center pr-10 text-base font-semibold gap-4 cursor-pointer">
            <li className="hover:bg-gray-200 rounded py-4 px-6"><a href="#roadmap">Roadmap</a></li>
            <li className="hover:bg-gray-200 rounded py-4 px-6"><a href="#nfts">NFTs</a></li>
            <li className="hover:bg-gray-200 rounded py-4 px-6"><a href='https://whitepaper.miningvoxels.com/' target='_blank'>Whitepaper</a></li>
            <li className="hover:bg-gray-200 rounded py-4 px-6"><a href="#token">Token</a></li>
            <li className="hover:bg-gray-400 hover:border-black justify-center items-center align-middle py-[10px] w-40 h-16 border-b-4 rounded-xl shadow-2xl text-3xl font-pixel bg-gray-300 border-gray-500 text-black hover:text-black text-center"><a href="https://play.miningvoxels.com/">PLAY</a></li>
          </ul>
          <button className="block sm:hidden py-3 px-4 mx-2 rounded focus:outline-none hover:bg-gray-200 group">
            <div className="w-5 h-1 bg-gray-600 mb-1"></div>
            <div className="w-5 h-1 bg-gray-600 mb-1"></div>
            <div className="w-5 h-1 bg-gray-600"></div>
            <div className="absolute top-0 -right-full h-screen w-8/12 bg-gray-200 border opacity-0 group-focus:right-0 group-focus:opacity-100 transition-all duration-300 z-10">
              <ul className="flex flex-col items-center w-full text-base pt-10">
                <button className="absolute top-0 right-0 md:hidden py-4 px-4 mx-2 rounded focus:outline-none hover:bg-gray-200 group">
                  <BiArrowToRight className="w-8 h-8"/>
                </button>
                <li className="hover:border-gray-700 py-4 px-6 w-full border-b-4 border-gray-400"><a href="#roadmap" className="z-20">Roadmap</a></li>
                <li className="hover:border-gray-700 py-4 px-6 w-full border-b-4 border-gray-400"><a href="#nfts">NFTs</a></li>
                <li className="hover:border-gray-700 py-4 px-6 w-full border-b-4 border-gray-400"><a href='https://whitepaper.miningvoxels.com/' target='_blank'>Whitepaper</a></li>
                <li className="hover:border-gray-700 py-4 px-6 w-full border-b-4 border-gray-400"><a href="#token">Token</a></li>
                <li className="hover:border-gray-700 py-4 px-6 w-full border-b-4 border-gray-400"><a href="#footer">Useful Links</a></li>
                <li className="hover:border-gray-700 py-4 px-6 w-full border-b-4 border-gray-400"><a href="https://play.miningvoxels.com/">PLAY</a></li>
              </ul>
            </div>
          </button>
        </div>
      </nav>
    );
}

export default NavBar;