import {CgSandClock , CgTimer , CgCheckO} from 'react-icons/cg'

function RoadMap(){
    return(
        <div className="bg-black text-white" id="roadmap">
        <div className="relative text-center py-4">
          <h1 className="text-8xl font-pixel">ROADMAP</h1>
        </div>
        <div className="flex flex-col md:flex-row sm:flex-col justify-center content-center gap-16 w-full mx-6">
          <div className="w-[80%] h-fit sm:w-[90%] md:w-[30%] border rounded-lg bg-white flex flex-col text-black content-center items-center gap-6 mt-8 py-4">
            <h1 className="font-pixel mt-6 text-4xl border-b-2 border-black border-dotted">PHASE 1</h1>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Smart Contract Development</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Game Development</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Creating NFTs</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Closed Beta(Testnet)</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Open Beta(Testnet)</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Whitelist Requests</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Game Launch</span>
              <CgCheckO className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
          </div>
          <div className="w-[80%] h-fit sm:w-[90%] md:w-[30%] border rounded-lg bg-white flex flex-col text-black content-center items-center gap-6 my-24 py-4">
            <h1 className="font-pixel mt-6 text-4xl border-b-2 border-black border-dotted">PHASE 2</h1>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Adding New Assets</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Adding New NFTs</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Mobile Development</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">IN-GAME Marketplace</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">New UI Update</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Downloadable Client</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
            <div className="flex flex-row gap-4">
              <span className="font-pixel text-2xl p-4">Season 2</span>
              <CgSandClock className="justify-center items-center content-center w-8 h-8 my-4"/>
            </div>
          </div>
        </div>
      </div>
    );
}

export default RoadMap;