import {BsDiscord} from 'react-icons/bs';

function Discord(){
    return(
        <div className="bg-gradient-to-br from-blue-900 via-indigo-950 to-sky-950" id="joinus">
        <div className="relative text-center py-4 text-white">
          <h1 className="text-8xl font-pixel">JOIN US</h1>
        </div>
        <div className="flex justify-center content-center items-center align-middle text-white">
          <div className="w-[80%] flex flex-col md:flex-row p-16 rounded-2xl justify-between items-center my-4 shadow-lg shadow-gray-900 gap-4">
            <BsDiscord className="w-[180px] h-[180px]"/>
              <div className="flex flex-col gap-4">
                <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">JOIN OUR COMMUNITY</span>
                <span className="font-pixel text-xl md:text-2xl lg:text-3xl">By joining our community, you can be informed about the latest updates and innovations quickly.</span>
                <button className="w-40 h-16 bg-gradient-to-r from-sky-500 via-blue-500 to-purple-500 rounded-xl shadow-lg text-2xl font-pixel text-white hover:text-black"><a href='https://discord.gg/butkKKH8Nr' target='_blank'>JOIN</a></button>
              </div>
          </div>
        </div>
        </div>
    )
}

export default Discord;