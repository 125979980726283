function Team(){
    return(
        <div className="bg-black text-white" id="drops">
        <div className="relative text-center py-4 text-white">
          <h1 className="text-8xl font-pixel">DROPS & SALES</h1>
        </div>
        <div className="flex flex-wrap justify-center content-center items-center gap-4 py-16 text-center px-6">
          <div className="w-[450px] h-[550px] border-4 border-dashed bg-gradient-to-br from-gray-800 via-black to-gray-950 border-white rounded-lg">
            <div className="flex justify-center content-center items-center w-full h-[450px] border-b-4 border-dashed">
              <a href='https://neftyblocks.com/collection/miningvoxels/drops/' target='_blank'><img alt="" src="nfts/1letterpack.png" className="h-[400px]"></img></a>
            </div>
            <div className="flex flex-col">
              <span className="font-pixel text-4xl">1 LETTER PACK</span>
              <span className="font-pixel text-4xl">EVENT SPECIAL</span>
            </div>
          </div>
          <div className="w-[450px] h-[550px] border-4 border-dashed bg-gradient-to-br from-gray-800 via-black to-gray-950 border-white rounded-lg">
            <div className="flex justify-center content-center items-center w-full h-[450px] border-b-4 border-dashed">
              <a href='https://neftyblocks.com/collection/miningvoxels/drops/' target='_blank'><img alt="" src="nfts/10letterpack.png" className="h-[400px]"></img></a>
            </div>
            <div className="flex flex-col">
              <span className="font-pixel text-4xl">10 LETTER PACK</span>
              <span className="font-pixel text-4xl">EVENT SPECIAL</span>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Team;