function Token(){
    return(
        <div className="bg-black" id="token">
        <div className="relative text-center py-4 text-white">
          <h1 className="text-8xl font-pixel">TOKEN</h1>
        </div>
        <div className="flex justify-center content-center items-center align-middle text-white">
          <div className="w-[80%] flex flex-col md:flex-row p-16 rounded-2xl justify-between items-center my-4 shadow-lg shadow-gray-900">
            <img alt="" src="MVT.png" className="w-[300px]"></img>
              <div className="flex flex-col gap-4">
                <span className="font-pixel text-2xl md:text-3xl lg:text-5xl border-b-2 border-black border-dotted">$MVT Mining Voxels Token</span>
                <span className="font-pixel text-xl md:text-2xl lg:text-3xl">You can earn the "MVT" token by playing the game. "MVT" is useful and highly important for your in-game trades and crafts.</span>
                <button className="w-40 h-16 bg-gradient-to-r from-orange-500 via-red-500 to-purple-500 rounded-xl shadow-lg text-2xl font-pixel text-white hover:text-black"><a href='https://alcor.exchange/trade/mvt-miningvoxelt_wax-eosio.token' target='_blank'>TRADE</a></button>
              </div>
          </div>
        </div>
        </div>
    )
}

export default Token;