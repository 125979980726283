import {AiOutlineCopyright , AiOutlineTwitter, AiFillInstagram} from 'react-icons/ai';
import {BsDiscord} from 'react-icons/bs';

function Footer(){
    return(
        <div className="bg-white" id="footer">
            <div className="flex flex-col sm:flex-row p-16 justify-between content-center align-middle items-center">
                <div className="flex">
                    <span className="font-pixel text-5xl">MININGVOXELS</span>
                    <AiOutlineCopyright className="mb-6"/>
                </div>
                <a href='https://twitter.com/miningvoxels' target='_blank'><AiOutlineTwitter className="w-16 h-16 cursor-pointer mx-4" /></a>
                <a href='https://discord.gg/butkKKH8Nr' target='_blank'><BsDiscord className="w-16 h-16 cursor-pointer mx-4"/></a>
                <a className="font-pixel text-4xl cursor-pointer mx-4" href='https://wax.atomichub.io/explorer/collection/wax-mainnet/miningvoxels' target='_blank'>ATOMICHUB</a>
                <a className="font-pixel text-4xl cursor-pointer mx-4" href='https://neftyblocks.com/collection/miningvoxels' target='_blank'>NEFTYBLOCKS</a>
                <a className="font-pixel text-4xl cursor-pointer mx-4" href='https://whitepaper.miningvoxels.com/' target='_blank'>WHITEPAPER</a>
                <div className="flex flex-col align-middle justify-center items-center">
                    <span className="font-pixel text-2xl">KADIKOY/Istanbul</span>
                    <span className="font-pixel text-xl">Registration Code: 1590690463</span>
                </div>
            </div>
        </div>
    );
}

export default Footer;